<template>
    <div class="app">
        <div class="header-form">
            <div class="admin_main_block_left">
                <el-form>
                    <el-form-item label="时间">
                        <el-date-picker
                                v-model="date"
                                @change="orderDate" @input="immediUpdate"
                                type="monthrange"
                                range-separator="~"
                                value-format="yyyy-MM"
                                format="yyyy-MM"
                                start-placeholder="开始月份"
                                end-placeholder="结束月份"
                        >
                            <i class="el-icon-date"></i>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                        >条件筛选
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
            <div class="admin_main_block_right">
                <div>
                    <el-button type="primary" @click="exportData" icon="el-icon-tickets"
                    >导出数据
                    </el-button
                    >
                </div>
            </div>
        </div>
        <el-table :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column
                    prop="id"
                    label="商家ID"
                    :show-overflow-tooltip="true"
            ></el-table-column>
          <el-table-column prop="appid" label="商户渠道">
            <template slot-scope="scope">
              <el-tag :type="shopChannel(scope.row.appid,1)" v-if="scope.row.appid">
                {{ shopChannel(scope.row.appid,0) }}
              </el-tag>
            </template>
          </el-table-column>
            <el-table-column
                    prop="companyName"
                    label="商家名称"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="businessPhone"
                    label="手机号（账号）"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="applyer"
                    label="申请人姓名"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="merchantType"
                    label="类型"
                    :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.merchantType == 1"
                    >个体
                    </el-tag
                    >
                    <el-tag type="warning" v-else-if="scope.row.merchantType == 2"
                    >企业
                    </el-tag
                    >
                    <el-tag type="primary" v-else-if="scope.row.merchantType == 3"
                    >小微
                    </el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column
                    prop="payCardNo"
                    label="收款码ID"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="shopName"
                    label="门店名称"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="category"
                    label="经营类型"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="shopAreaCode"
                    label="所属区域"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                    prop="givingPercent"
                    label="赠豆比例"
                    :show-overflow-tooltip="true"
            >
                <template slot-scope="scope"> {{ scope.row.givingPercent }}%</template>
            </el-table-column>
            <el-table-column
                    prop="applyDate"
                    label="申请时间"
                    :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.state == 1">{{
                        scope.row.stateText
                        }}
                    </el-tag>
                    <el-tag type="warning" v-else-if="scope.row.state == 2">{{
                        scope.row.stateText
                        }}
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.state == 0">{{
                        scope.row.stateText
                        }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="180px">
                <template slot-scope="scope">
                    <el-button
                            type="success"
                            icon="el-icon-tickets"
                            v-if="scope.row.state == 1"
                            @click="$router.push({ name: 'merchantaudit', query: scope.row })"
                    >查看
                    </el-button
                    >
                    <el-button
                            :type="scope.row.merchantImageTypes > 0 ? 'warning' : 'success'"
                            icon="el-icon-edit"
                            v-else
                            @click="$router.push({ name: (scope.row.merchantImageTypes > 0 ? 'merchantEdit' : 'merchantaudit'), query: scope.row })"
                    >{{scope.row.merchantImageTypes > 0 ? '审核' : '查看'}}
                    </el-button
                    >
                    <!--          {{scope.row.state}}/{{scope.row.merchantImageTypes}}-->
                </template>
            </el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
            <el-pagination
                    @current-change="currentChange"
                    @size-change="handleSizeChange"
                    background
                    :total="totalData"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    layout="sizes, prev, pager, next, total, jumper"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
  import {shopChannel} from "@/plugins/map.js";

  export default {
        components: {},
        props: {
            state: Number,
        },
        data() {
            return {
              shopChannel,
                list: [],
                date: "",
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                selectId: "",
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    categoryCode: "",
                    commodityName: "",
                    id: 0,
                    keyWord: "",
                    latitude: "",
                    longitude: "",
                    merchantId: 0,
                    // "orderType": "1：根据返还悦豆最高排序,2：根据最近的距离排序,3：根据好评排序,4：根据消量排序",
                    responsibleName: "",
                    responsiblePhone: "",
                    searchName: "",
                    shopName: "",
                    state: 0,
                },
            };
        },
        methods: {
            listData() {
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.where.shopId = this.shopId;
                this.$post(this.$api.shopApplyList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.where.pageNum = res.data.pages;
                    this.list = res.data.records;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startDate = obj[0];
                    this.where.endDate = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if (e == null) {
                        this.where.startDate = '';
                        this.where.endDate = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },
        },
        created() {
            this.where.state = this.state;
            this.listData();
        },
    };
</script>
