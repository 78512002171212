//订单状态
export const orderStatusMap = {
    1: "支付中",
    2: "支付成功",
    8: "支付失败",
    10: "退款中",
    11: "退款成功",
    12: "退款失败"
}
//交易类型
export const tradeTypeMap = {
    "01":"消费",
    "02":"商城邮费",
    "03":"设备押金",
    "06":"广告充值",
    "07":"购买礼包",
    "08":"会员卡",
}
//支付类型
export const payTypeMap = {
    "WECHAT":"微信",
    "ALIPAY":"支付宝",
    "UNIONPAY":"银联"
}
//支付方式
export const payWayMap = {
    "01":"B2C被扫支付",
    "02":"JSAPI支付",
    "03":"小程序支付",
    "04":"APP支付",
    "05":"NATIVE支付",
    "06":"MWEB支付",
}

//礼包类型
export const giftBagType = {
    "01":"商家1000",
    "02":"用户9.9",
    "03":"用户298",
    "04":"用户1000",
    "05":"商家会员卡",
}
//分润状态
export const profitStatus = {
        "0":"未分润",
        "1":"已分润",
        "2":"不需要分润",
}
//分润状态
export const rewardFlg = {
    "1":"惠豆",
    "2":"商品",
}

//礼包完成状态
export const giftBagStatus = {
    "0":"待完成",
    "1":"已完成",
}

export function getStyle(status) {
    let str = '';
    switch (status) {
        case "1":
            str = "";
            break;
        case "2":
            str = "primary";
            break;
        case "3":
            str = "warning";
            break;
        case "4":
            str = "primary";
            break;
        case "5":
            str = "primary";
            break;
        case "6":
            str = "danger";
            break;
        case "7":
            str = "success";
            break;
        case "8":
            str = "info";
            break;
    }
    return str;
}

/**
 * 商户渠道
 * @param val 值
 * @param type 样式；0：显示值；1：显示样式
 * @returns {string}
 */
export function shopChannel(val,type) {
    let str = '';
    if(type === 0){
        switch (val) {
            case "hlj":
                str = "惠链接";
                break;
            case "ymh":
                str = "有免惠";
                break;
        }
    } else if(type === 1){
        switch (val) {
            case "hlj":
                str = "warning";
                break;
            case "ymh":
                str = "success";
                break;
        }
    }
    return str;
}
